import '@unocss/reset/tailwind.css'

import '../styles/globals.scss'
import type { AppProps } from 'next/app'
import Footer from '../layouts/Footer'
import Head from 'next/head'

function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <title>SHElikes（シーライクス）無料体験レッスン受付中</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
      </Head>
      <Component {...pageProps} />
      <Footer />
    </>
  )
}

App.getInitialProps = async () => ({ pageProps: {} })

export default App
